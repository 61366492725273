/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-template-curly-in-string */
import { Box, Input, Autocomplete, Container } from "@components";
import { isFunc, checkInput, compressImage } from "@utils";
import { useCallback, useState, useEffect } from "react";
import { 
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  DialogContent,
  TextField 
} from "@mui/material";
import Actions from "./actions";
import { useArticleGetAll as useGetAll, useGoodGetById2 as UseGetById, useImagesGetWithStatus } from "@api";

export default (props) => {
  const { onClose, onSave, id } = props;

  const [data, setData] = useState({});
  const [save, setSave] = useState(false);
  const [error, setError] = useState({});
  const [callbackGet, loading] = UseGetById();

  const [isFinishedLoadImage, setFinishedLoadImage] = useState(false);
  const [isUpdateImage, setIsUpdateImage] = useState(false);
  const [imagePlaceholder, setImagePlaceholder] = useState(null);
  const [imagePlaceholderStatus, setImagePlaceholderStatus] = useState(404);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (id) {
      callbackGet(id, setData);
    }
  }, [id, callbackGet]);

  const validate = useCallback((data) => {
    return checkInput(data, getValidateArray());
  }, []);

  const getValidateArray = () => {
    const result = [
      {
        name: "caption",
        maxLength: {
          val: 200,
          errorMessage: "Заголовок не должен быть длиннее ${val} символов"
        },
        minLength: {
          val: 3,
          errorMessage: "Заголовок не должен быть короче ${val} символов"
        }
      },
      {
        name: "articleId",
        isNotNull: { errorMessage: "Артикул должен быть выбран" }
      },
      {
        name: "addonProps.measureCalcValue",
        isNotNull: { errorMessage: "Необходимо выбрать тип товара" }
      },
      {
        name: "price.purchase",
        isNotNull: { errorMessage: "Поле цены не может быть пустым" },
        maxValue: {
          val: 9999,
          errorMessage: "Цена не должна быть больше ${val}"
        },
        minValue: {
          val: 0,
          errorMessage: "Цена не должна быть меньше ${val}"
        },
        isNumeric: { errorMessage: "Цена должна быть числом" }
      }
    ]
      if (data.addonProps.measureCalcValue !== null && data.addonProps.measureCalcValue !== "") {
        result.push(
          {
            name: "warehouse.availability",
            isNotNull: { errorMessage: "Поле количества товара не может быть пустым" },
            maxValue: {
              val: "9999",
              errorMessage: "Количество товара не может быть больше ${val}"
            },
            minValue: {
              val: "0",
              errorMessage: "Количество товара не может быть меньше ${val}"
            },
            isInteger: { errorMessage: "Количество товара должно быть целым числом" }
          }
        )
        return result;
      }
      if (data.addonProps.measureCalcValue === "piece" || !data.addonProps.measureCalcValue) {
        return result;
      }
      if (data.addonProps.measureCalcValue === "weight") {
        result.push({
          name: "weight",
          isNotNull: { errorMessage: "Поле не может быть пустым" },
          maxValue: {
            val: 9999,
            errorMessage: "Вес не должен быть больше ${val} грамм"
          },
          minValue: {
            val: 0,
            errorMessage: "Вес не должен быть меньше ${val} грамм"
          }
        });
        return result;
      }
  };

  const handleOnSave = useCallback(() => {
    setSave((prev) => !prev);
  }, []);

  useEffect(() => {
    if (save) {
      isFunc(onSave, { ...data, articleId: data?.articleId?.id, id, image });
    }
    if (data.personalImage) {
      loadExistedImage(data.personalImage);
    }
  }, [onSave, data, save, id, image]);

  useEffect(() => {
    console.log("data = ", data);
    if (!data.addonProps) {
      data.addonProps = { measureCalcValue : "" };
    }
    setError(validate(data));
  }, [data, validate]);

  useEffect(() => {
  console.log("error = ", error);
  }, [error]);

  const handleChange = useCallback((param) => {
    return (event) => {
      console.log(param, event.target.value);
      setData((prev) => {
        if (param === "measureCalcValue") {
          if (prev.addonProps) {
            prev.addonProps.measureCalcValue = event.target.value;
          } else {
            prev.addonProps = { measureCalcValue: event.target.value };
          }
        }
        else if (param === "price.purchase") {
          if (prev.price) {
            prev.price.purchase = event.target.value;
          } else {
            prev.price = { purchase: event.target.value };
          }
        } else if (param === "warehouse.availability") {
          if (prev.warehouse) {
            prev.warehouse.availability = event.target.value;
          } else {
            prev.warehouse = { availability: event.target.value };
          }
        } else {
          prev[param] = event.target.value;
        }
        return { ...prev };
      });
    };
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("Файл не выбран.");
      return;
    }

    if (!file.type.startsWith("image/")) {
      console.log("Выбранный файл не является изображением.");
      return;
    }

    try {
      setFinishedLoadImage(false);
      setIsUpdateImage(true);
      const compressedBlob = await compressImage(file);
      const compressedFile = new File([compressedBlob], file.name, { type: file.type });
      setImage(compressedFile);
      setFinishedLoadImage(true);
      console.log(
        "Изображение успешно сжато. Картинка весила: " +
          file.size / 1000 +
          " Кб, сжата до: " +
          compressedBlob.size / 1000 +
          " Кб"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const loadExistedImage = async (imageName) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { url, status } = await useImagesGetWithStatus(imageName);
    setImagePlaceholderStatus(status);
    if (status === 200) {
      setImagePlaceholder(url);
    }
  };

  const handleChangeMeasureCalc = (event) => {
    console.log(event.target.value);
    handleChange("measureCalcValue")(event);
  };

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            padding: (theme) => theme.spacing(1, 0),
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%"
          }}
        >
          <Container error={error} data={data} onChange={handleChange} loading={loading}>
            <Input name="caption" caption="Заголовок" />
            <Input isMultiline={true} name="description" caption="Описание" />
            <Autocomplete name="articleId" caption="Артикул" useGet={useGetAll} />
            {/* TODO: - show image */}
            <div style={{ border: "1px solid gray", borderRadius: "5px", padding: "16px", backgroundColor: "#f0f0f8" }}>
              <p style={{ margin: "-4px 0 8px 0px" }}>
                Изображение товара:{" "}
                {(data?.personalImage ? data?.personalImage : "Не выбрано") +
                  (imagePlaceholderStatus !== 200 && data?.personalImage ? " (Файл изображения не найден)" : "")}
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                {imagePlaceholder ? (
                  <img alt="productImage" style={{ width: "100px", height: "100px" }} src={imagePlaceholder} />
                ) : (
                  false
                )}
                <TextField
                  style={{
                    width: "100%",
                    marginLeft: imagePlaceholderStatus !== 200 || !imagePlaceholder ? "0px" : "16px"
                  }}
                  type="file"
                  onChange={handleImageChange}
                  inputProps={{ accept: "image/*" }}
                />
              </div>
            </div>
            {
              (data.addonProps && !data.addonProps.measureCalcValue) &&
          <FormControl error={!!error?.["addonProps.measureCalcValue"]} sx={["width: 100%"]}>
                <FormLabel 
                  id="demo-radio-buttons-group-label" 
                  name="addonProps.measureCalcValue">
                  Мера исчисления товара
                  </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={(data.addonProps && data.addonProps.measureCalcValue) ? data.addonProps.measureCalcValue : ""}
                  onChange={handleChangeMeasureCalc}
                  row
                >
                <FormControlLabel value="piece" control={<Radio />} label="В штуках" />
                <FormControlLabel value="weight" control={<Radio />} label="В граммах" />
              </RadioGroup>
              <FormHelperText>{error?.["addonProps.measureCalcValue"]}</FormHelperText>
          </FormControl>
            }
            { (data.addonProps && data.addonProps.measureCalcValue === "weight") &&
            <Input name="teaCategory" caption="Категория чая" />
            }
            {
              (data.addonProps && data.addonProps.measureCalcValue) &&
              <Input 
              name="price.purchase" 
              caption={ 
                (data.addonProps && data.addonProps.measureCalcValue === "piece") ?
                 "Цена в долларах за штуку" : "Цена в долларах за грамм"
                } 
              />
            } 
            {
              (data.addonProps && data.addonProps.measureCalcValue === "weight") &&
              <Input name="weight" caption="Вес упаковки в граммах" />
            }
            {
              (data.addonProps && data.addonProps.measureCalcValue !== null && data.addonProps.measureCalcValue !== "") &&
              <Input name="warehouse.availability" caption="Количество товара в наличии" />
            }
          </Container>
        </Box>
      </DialogContent>
      <Actions
        handleOnSave={handleOnSave}
        disabled={error.isError || (isFinishedLoadImage === false && isUpdateImage === true)}
        onClose={onClose}
      />
    </>
  );
};
