export const compressImage = (file, maxSize = 1 * 512 * 512) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    if (file.size < 300000) {
      // Если исходник картинки весит меньше 300 Кб, то не сжимаем её
      resolve(file);
      return;
    }

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;

        // Ограничиваем размеры, если нужно
        if (width > 1000 || height > 1000) {
          if (width > height) {
            height *= 1000 / width;
            width = 1000;
          } else {
            width *= 1000 / height;
            height = 1000;
          }
        }

        canvas.width = width;
        canvas.height = height;

        // Рисуем изображение на canvas
        ctx.drawImage(img, 0, 0, width, height);

        const compress = () => {
          canvas.toBlob(
            (blob) => {
              if (blob && blob.size <= maxSize) {
                resolve(blob); // Возвращаем результат, если размер подходит
              } else {
                // Пробуем уменьшить размер путем снижения разрешения
                if (width > 100 || height > 100) {
                  width *= 0.9;
                  height *= 0.9;
                  canvas.width = width;
                  canvas.height = height;
                  ctx.drawImage(img, 0, 0, width, height);
                  compress();
                } else {
                  reject(Error("Не удалось сжать изображение до нужного размера."));
                }
              }
            },
            "image/png" // Сохраняем сжатое изображение в формате PNG
          );
        };

        compress();
      };

      img.onerror = () => {
        reject(Error("Не удалось загрузить изображение."));
      };
    };

    reader.onerror = () => {
      reject(Error("Ошибка чтения файла."));
    };

    reader.readAsDataURL(file);
  });
};
