import { memo, useEffect, useState } from "react";
import { Input, Text } from "@components";
import { areEqualObject } from "@utils";

const Default = memo((props) => {
  const { id, description, expanded } = props;
  const [insertedWeight, setInsertedWeight] = useState(1);

  useEffect(() => {
    if (expanded) {
      console.log(expanded, id);
      return () => {
        console.log("unMount", id);
      };
    }
  }, [expanded, id]);

  const handleChange = (text) => {
    setInsertedWeight(text);
  };

  const calculatePriceInUsd = () => {
    return (parseFloat(props.price.purchase) * parseInt(getCorrectInsertedWeight())).toFixed(2);
  };

  function calculatePriceInRub() {
    return (parseFloat(props.price.purchaseRub) * parseInt(getCorrectInsertedWeight())).toFixed(2);
  }

  const calculatePriceInTon = () => {
    return ((props.price.purchaseNanoTon / 1e9) * parseInt(getCorrectInsertedWeight())).toFixed(6);
  };

  const getCorrectInsertedWeight = () => {
    return (insertedWeight === "" || insertedWeight === undefined) ? '1' : insertedWeight;
  };

  return (
    <>
      <Text caption={description} />
      <div>
        <Text style={{ marginTop: "16px" }} caption={
          props.addonProps.measureCalcValue === "weight" ?
          'Калькулятор цены (цена в долларах за 1 грамм) х (Количество грамм):'
          : 'Калькулятор цены (цена в долларах за 1 штуку) х (Количество штук):'
          } />
        <div style={{ display: "flex", alignItems: "center" }}>
        <Text caption={`${props.price.purchase} x `} />
        <input
        style={{ margin: "0 8px", width: "200px" }}
        name="search"
        inputMode="numeric"
        caption="Количество грамм"
        value={insertedWeight}
        onChange={(e) => {handleChange(e.target.value)}}
      />
      <Text caption={` = `} />
      <Text 
      sx={{ 
        margin: "0 8px", 
        borderRadius: "4px", 
        padding: "4px", 
        border: '1px solid gray'
        }} caption={`$${calculatePriceInUsd()}`} />
      <Text 
      sx={{ 
        margin: "0 8px", 
        borderRadius: "4px", 
        padding: "4px", 
        border: '1px solid gray'
      }} 
      caption={`${calculatePriceInRub()} ₽`} />
      <Text 
      sx={{ 
        margin: "0 8px", 
        borderRadius: "4px", 
        padding: "4px", 
        border: '1px solid gray'
      }} 
      caption={`${calculatePriceInTon()} TON`} />
        </div>
      </div>
      {/* <Text caption={caption} /> */}
    </>
  );
}, areEqualObject);

export default Default;
