/* eslint-disable no-unsafe-optional-chaining */
import { memo, useEffect } from "react";
import { Text } from "@components";
import { areEqualObject } from "@utils";

const Default = memo((props) => {
  const { id, expanded } = props;

  // const description = props?.good?.description
  // const articleDescription = props?.good?.article?.description
  const articleCaption = props?.good?.article?.caption;

  useEffect(() => {
    if (expanded) {

      console.log(expanded, id);
      return () => {
        console.log("unMount", id);
      };
    }
  }, [expanded, id]);

  return (
    <>
      <Text
        style={{ marginBottom: "6px" }}
        caption={`Название артикула: ${articleCaption}`}
        checkRender={() => !!articleCaption}
      />
      <Text
        style={{ marginBottom: "20px" }}
        caption={`Номер артикула: ${props?.good?.article?.id}`}
        checkRender={() => !!props?.good?.article?.id}
      />
      {
        props?.good?.addonProps?.measureCalcValue === "weight" && 
        <Text
        style={{ marginBottom: "6px" }}
        caption={`Размер единицы заказанного товара в граммах: 
          ${
            props?.packageWeight
              ? props?.packageWeight.replace(/\D/g, "")
              : "(Нет данных о весе товара на момент добавления в заказ)"
          } `}
        checkRender={() => !!props}
      />
      }
      <Text
        style={{ marginBottom: "6px" }}
        caption={`Количество единиц заказанного товара: ${props?.count}`}
        checkRender={() => !!props?.count}
      />
      {
        props?.good?.addonProps?.measureCalcValue === "weight" &&
        <Text
        style={{ marginBottom: "20px" }}
        caption={`Заказанный вес данного товара в граммах: 
          ${
            props?.count *
            (props?.packageWeight
              ? props?.packageWeight.replace(/\D/g, "")
              : "(Нет данных о весе товара на момент добавления в заказ)")
          }`}
        checkRender={() => !!props?.count}
      />
      }
      {props?.fixedCostUsdt && props?.fixedCostRub && props?.fixedCostTon && (
        <Text
          style={{ marginBottom: "6px" }}
          /* eslint-disable-next-line no-unsafe-optional-chaining */
          caption={`Стоимость единицы данного товара:
           $${(props?.fixedCostUsdt * (props?.packageWeight ? props?.packageWeight.replace(/\D/g, '') : 1)).toFixed(2)}, 
           ${(props?.fixedCostTon * (props?.packageWeight ? props?.packageWeight.replace(/\D/g, '') : 1)).toFixed(2)} TON, 
           ${(props?.fixedCostRub * (props?.packageWeight ? props?.packageWeight.replace(/\D/g, '') : 1)).toFixed(2)} ₽`}
          checkRender={() => !!props?.fixedCostUsdt && !!props?.fixedCostRub && !!props?.fixedCostTon}
        />
      )}
      {props?.fixedCostUsdt && props?.fixedCostRub && props?.fixedCostTon && (
        <Text
          style={{ marginBottom: "16px" }}
          /* eslint-disable-next-line no-unsafe-optional-chaining */
          caption={`
          Общая сумма данного заказанного товара: 
          $${((props?.fixedCostUsdt * (props?.packageWeight ? props?.packageWeight.replace(/\D/g, '') : 1)) * props?.count).toFixed(2)}, 
          ${((props?.fixedCostTon * (props?.packageWeight ? props?.packageWeight.replace(/\D/g, '') : 1)) * props?.count).toFixed(2)} TON, 
          ${((props?.fixedCostRub * (props?.packageWeight ? props?.packageWeight.replace(/\D/g, '') : 1)) * props?.count).toFixed(2)} ₽`}
          checkRender={() => !!props?.good?.price?.purchase}
        />
      )}
      {/* <Text
        style={{marginBottom: "16px"}}
        caption={`Описание артикула: ${articleDescription}`}
        checkRender={() => !!articleDescription}
      />
      <Text
        style={{marginBottom: "16px"}}
        caption={`Описание товара: ${description}`}
        checkRender={() => !!description}
      /> */}
    </>
  );
}, areEqualObject);

export default Default;
